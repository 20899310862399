<template>
  <div
    style="
      display: flex;
      flex-direction: row;
      height: 100vh;
      text-align: center;
      flex: 1;
      align-content: center;
      justify-content: center;
      align-items: center;
    "
  >
    <iframe src="https://vrh-6836.worktalk.store"></iframe>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
export default {
  name: "SaleSmartly",
  components: {
    Loading,
  },
};
</script>

<style scoped>
/* styles.css */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

#iframeContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none; /* Removes the default border of the iframe */
}
</style>
