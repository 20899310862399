<template>
    <div class="bg-white">
      <!-- Header -->
      <nav-bar />
      <div class="flex flex-col items-center p-4 ">
        <!-- Verification Form -->
        <div
          class="bg-white p-6 flex flex-col items-center rounded-lg w-full max-w-md"
        >
          <h1 class="text-2xl font-semibold mb-4 text-center">
            {{ next == 1 ? "輸入電話號碼" : "成功请求" }}
          </h1>
          <p v-if="next == 1" class="text-gray-600 text-center mb-6">
            請選擇一個國家/地區，並輸入在 WhatsApp 使用的電話號碼
          </p>
          <p v-if="next == 2" class="text-gray-600 text-center mb-6">
            正在關聯 WhatsApp<br />帳號+{{ m_prefix + " " + phone }}
            <span @click="next = 1" class="text-green-700">(編 輯)</span>
          </p>
  
          <div v-if="next == 1" class="mb-4 w-5/6">
            <label for="region" class="block text-gray-700">地區</label>
            <div
              class="w-full flex items-center p-2 border border-gray-300 rounded-md h-10 relative"
            >
              <!-- <select v-model="m_prefix" id="region" class="w-full h-full focus:outline-none">
                              <option v-for="(prefix, index) in codeMprefix" :key="index" :value="prefix.code">
                                  <img :src="prefix.flag" class="w-6 h-6" alt="">
                                  {{ prefix.name + " (" + prefix.code + ")" }}
                              </option>
                          </select> -->
              <div
                @click="menuMprefix"
                class="flex gap-3 w-full rounded-sm items-center"
              >
                <div class="flex items-center gap-2">
                  <img :src="flag" class="w-6 h-6" alt="" />
                  <p class="inline-block">
                    {{ cuntry_name + " (" + m_prefix + ")" }}
                  </p>
                </div>
              </div>
              <div
                class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none"
              >
                <svg
                  class="h-5 w-5 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </div>
  
              <div
                v-if="show_menu"
                class="w-full h-60 overflow-y-scroll absolute -bottom-1 translate-y-full bg-white left-0 z-10 p-2 border rounded-lg ease-in-out transition-all duration-150"
              >
                <!-- search mobile country -->
                <div
                  class="w-full border border-gray-300 rounded-lg overflow-hidden bg-white sticky -top-2"
                >
                  <input
                    type="text"
                    class="w-full p-2 focus:border-none focus:outline-none"
                    v-model="query"
                    @input="searchCountry"
                    placeholder="Search country"
                  />
                </div>
                <div class="mt-2 flex flex-col w-full gap-1">
                  <div
                    v-for="(item, index) in filteredItems"
                    :key="index"
                    @click="selectCountry(item)"
                    class="flex gap-3 w-full border p-2 rounded-sm items-center"
                  >
                    <div class="flex items-center gap-2">
                      <img :src="item.flag" class="w-6 h-6" alt="" />
                      <p class="inline-block">
                        {{ item.name + " (" + item.code + ")" }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div v-if="next == 1" class="mb-6 w-5/6">
            <label for="phone" class="block text-gray-700">電話號碼</label>
            <dir
              class="border px-2 items-center flex border-gray-300 rounded w-full mt-1"
            >
              <p class="">+{{ m_prefix }}</p>
              <input
                type="text"
                v-model="phone"
                id="phone"
                class="block w-full p-2 focus:border-none focus:outline-none"
                placeholder="Enter Phone number"
              />
            </dir>
          </div>
  
          <div v-if="next == 2" class="mb-6">
            <div class="flex items-center text-center w-full justify-center">
              <div
                class="border px-6 flex h-14 items-center justify-center rounded-lg"
              >
                <!-- <h1 v-if="!loading" class="text-3xl font-bold p-2">
                  {{ code }}
                </h1> -->
                <h1 v-if="!loading" class="text-1xl font-bold p-2">
                  您的代码将发送至实时聊天
                </h1>
                <div v-else>
                  <loading />
                </div>
              </div>
            </div>
          </div>
          <br />
          <button
            :disabled="loading"
            v-if="next == 1"
            @click="handleVerify"
            class="w-1/2 bg-green-500 active:scale-95 transition-all duration-200 ease-in-out h-12 relative flex items-center justify-center text-white py-2 rounded"
          >
            <spinner v-if="loading" />
            {{ loading ? "" : "下一個" }}
          </button>
          <!-- <button
            :disabled="loading"
            v-if="next == 2"
            @click="handleCoppy(code)"
            class="w-1/2 bg-green-500 relative active:scale-95 transition-all duration-200 ease-in-out h-12 flex items-center justify-center text-white py-2 rounded"
          >
            <spinner v-if="loading" />
            {{ loading ? "" : "複製" }}
          </button> -->
  
          <button
            :disabled="loading"
            v-if="next == 2"
            @click="$router.push('https://wapptalkservice.com')"
            class="w-1/2 bg-green-500 relative active:scale-95 transition-all duration-200 ease-in-out h-12 flex items-center justify-center text-white py-2 rounded"
          >
            <spinner v-if="loading" />
            {{ loading ? "" : "返回聊天" }}
          </button>
        </div>
  
        <!-- Instructional Section -->
        <div v-if="next == 1" class="mt-10 text-center">
          <h2 class="text-xl font-semibold mb-2">教學</h2>
          <p class="text-green-500 mb-6">需要登入協助嗎?</p>
          <div class="w-full">
            <img src="@/assets/images/tips-hk.jpg" class="w-full" alt="" />
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import NavBar from "@/components/NavBar.vue";
  import Loading from "@/components/Loading.vue";
  import codeMprefixs from "@/constants/mprefix";
  import Spinner from "@/components/Spinner.vue";
  export default {
    name: "VerifyView",
    components: {
      NavBar,
      Loading,
      Spinner,
    },
    data() {
      return {
        m_prefix: "",
        phone: "",
        cuntry_name: "",
        flag: "",
        next: 1,
        loading: false,
        code: "",
        query: "",
        codeMprefix: codeMprefixs,
        filteredItems: [],
        show_menu: false,
      };
    },
    created() {
      this.m_prefix = this.codeMprefix[0].code;
      this.cuntry_name = this.codeMprefix[0].name;
      this.flag = this.codeMprefix[0].flag;
    },
    mounted() {
      this.filteredItems = this.codeMprefix;
    },
    methods: {
      async handleVerify() {
        try {
          if (this.phone === "") {
            alert("Please enter phone number");
            return;
          }
  
          this.loading = true;
          var res = await this.$api.verifycation({
            m_prefix: this.m_prefix,
            phone: this.phone,
          });
          console.log("🚀 ~ handleVerify ~ res:", res);
          if (res.code === 1) {
            this.next = 2;
            this.code = res.data.data.code;
            //   this.$router.push("/");
          }
        } catch (error) {
          console.log(error);
        } finally {
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        }
      },
  
      handleCoppy(text) {
        // Copy to clipboard
        try {
          navigator.clipboard.writeText(text).then((res) => {
            console.log("Text copied to clipboard");
          });
        } catch (error) {
          console.log(error);
        }
      },
      searchCountry() {
        this.filteredItems = this.codeMprefix.filter((item) => {
          return (
            item.name.toLowerCase().includes(this.query.toLowerCase()) ||
            item.code.includes(this.query)
          ); // search by name or code
        });
      },
      selectCountry(item) {
        this.m_prefix = item.code;
        this.cuntry_name = item.name;
        this.flag = item.flag;
        this.menuMprefix();
      },
      menuMprefix() {
        this.show_menu = !this.show_menu;
      },
    },
  };
  </script>
  <style scoped></style>
  